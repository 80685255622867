import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import axios from "axios";
import { Web3ReactProvider } from "@web3-react/core";
// import Web3 from "web3";
import { Web3Provider } from "@ethersproject/providers";

// axios.defaults.baseURL = "http://192.168.0.58:8001/";
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

function getLibrary(provider: any) {
  return new Web3Provider(provider);
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Web3ReactProvider>
  );
}

export default App;
