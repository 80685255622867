import React from "react";
import { Redirect, Route, Switch } from "react-router";
import loadable from "@loadable/component";

const ScanPage = loadable(() => import("pages/ScanPage"));
const CreatePage = loadable(() => import("pages/CreatePage"));
const InfoPage = loadable(() => import("pages/InfoPage"));

const Routes = () => {
  return (
    <Switch>
      <Redirect exact path="/" to="/publish" />
      <Route path="/publish" exact component={CreatePage} />
      <Route path="/scan" exact component={ScanPage} />
      <Route path="/info" exact component={InfoPage} />
    </Switch>
  );
};

export default Routes;
