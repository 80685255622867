import styled from "@emotion/styled";

export const Loader = styled.div`
  width: 200px;
  height: 100px;
  position: relative;
  margin: 0 auto;
  &:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 64px;
    height: 64px;
    margin-top: -32px;
    margin-left: -32px;
    border-radius: 50%;
    border: 4px solid lightgrey;
    border-top-color: #00a3d6;
    animation: spinner 0.8s linear infinite;
    transform: translate(-50%, -50%);
  }
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
